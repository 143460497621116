import Cookies from 'js-cookie';

import { Services, TileDisplayMode, TileState } from 'components-typescript-react';

import { LenderName, ProductNames } from '@app-types';
import { CookieType } from '@utils/eligibilityBeaconPersistence/types';

import { isNumberInteger, resolveServiceProductInfoFromLender } from './helper';
import { EligibilityCheckResult, EligibilityRecord, ResolvedServiceProductInfo } from './types';

export const getEligibilityCheckLimitValue = (): number => {
  const value = Cookies.get(CookieType.EligibilityCheckLimit);
  if (typeof value === 'number') {
    return value > 0 ? value : 0;
  }

  if (typeof value === 'string') {
    try {
      const rst = parseFloat(value.replace(',', ''));
      return Number.isNaN(rst) ? 0 : rst;
    } catch (err) {
      console.log(err);
    }
  }

  return 0;
};

export const checkCookieEligibility = (): EligibilityRecord => {
  const eligibilityHistory: EligibilityRecord = {
    isEligible: false,
    creditLimit: 0,
    lenderName: '',
    isEstimatedCredit: false,
  };

  const eligibilityCheckStatus = Cookies.get(CookieType.EligibilityCheckStatus);
  const eligibilityLender = Cookies.get(CookieType.EligibilityCheckLender);

  if (eligibilityCheckStatus === 'false') {
    return eligibilityHistory;
  }

  const lenderServices = {
    blackhorse: Services.blackhorseFlexpay,
    newpay: Services.blackhorseNewpay,
  };

  let rst: EligibilityRecord = eligibilityHistory;
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  Object.entries(lenderServices).forEach(([lender, _service]) => {
    if (eligibilityLender === lender && eligibilityCheckStatus === 'true') {
      rst = {
        isEligible: true,
        creditLimit: getEligibilityCheckLimitValue(),
        lenderName: eligibilityLender,
        isEstimatedCredit: true,
      };
      return rst;
    }
  });

  if (eligibilityCheckStatus === 'PREQUALIFICATION_ACCEPT') {
    rst = {
      isEligible: true,
      creditLimit: getEligibilityCheckLimitValue(),
      lenderName: LenderName.NEWPAY,
      isEstimatedCredit: true,
    };
    return rst;
  }

  return rst;
};

export const checkEligibilityResult = (originalProduct: ProductNames): EligibilityCheckResult => {
  const eligibilityHistory: EligibilityRecord = checkCookieEligibility();
  const eligibleServiceProductInfo: ResolvedServiceProductInfo | undefined = eligibilityHistory.isEligible
    ? resolveServiceProductInfoFromLender(originalProduct, eligibilityHistory.lenderName)
    : undefined;

  return {
    eligibilityHistory,
    eligibleServiceProductInfo,
  };
};

export function updateTileStateWithCreditInfo(
  partialTileState: Partial<TileState>,
  eligibilityHistory: EligibilityRecord
): Partial<TileState> {
  if (!eligibilityHistory.isEligible) {
    return {
      ...partialTileState,
      isEligible: false,
      creditLimitValue: 0,
      creditLimit: '',
    };
  }

  const displayMode = TileDisplayMode.flippableEligibleMsg;
  const creditLimitEstimated = eligibilityHistory.isEstimatedCredit;
  const creditLimitValue = eligibilityHistory.creditLimit;
  const dp = isNumberInteger(eligibilityHistory.creditLimit) ? 0 : 2;
  const creditLimit = Number(eligibilityHistory.creditLimit).toFixed(dp);

  return {
    ...partialTileState,
    isEligible: true,
    displayMode,
    creditLimit,
    creditLimitEstimated,
    creditLimitValue,
  };
}
