import { ApplicationEventType, ApplicationReasons, EventType, LenderName } from '@app-types';
import { SourceComponent } from '@utils/Event/types';

export interface PublicApiCreateApplicationResponseBody {
  _links: {
    self: {
      href: string; // '/application/split/f7210019-2e4a-49f7-bd7b-b167285c5424'
    };
  };
  id: string; // 'f7210019-2e4a-49f7-bd7b-b167285c5424'
  'app:status': {
    last_updated: string; // '2021-04-28T07:33:32.038Z'
    state: string; // 'success'
    pollInterval: number;
    _links: {
      self: {
        href: string; // '/application/split/f7210019-2e4a-49f7-bd7b-b167285c5424/status'
      };
    };
  };
}

export enum PublicApiApplicationStates {
  success = 'success',
  failed = 'failed',
  pending = 'pending',
  initialised = 'initialised',
  referred = 'referred',
  denied = 'denied',
}

export interface PublicApiApplicationStatusErrorResponseBody {
  code: number;
  message: string;
}

export interface PublicApiApplicationStatusResponseBody {
  last_updated: string; // '2015-07-20T15:49:04-07:00'
  state: PublicApiApplicationStates;
  _links: {
    workflow?: {
      href: string;
    };
    self: {
      href: string; // '/application/split/f7210019-2e4a-49f7-bd7b-b167285c5424/status'
    };
  };
  receipt?: ApplicationEventType | null;
  reason?: ApplicationReasons | null;
}

export interface PublicApiCapturedEventPayload {
  lender: LenderName;
  type: EventType;
  isStandalone: boolean;
  sourceComponent?: SourceComponent;
  stepAction?: string;
  pageId: string;
  createdAt: string;
  applicationId?: string;
  orderId?: string;
}
