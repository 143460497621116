import {
  ApiAdapter,
  Application,
  ApplicationReasons,
  ApplicationStatus,
  ApplicationStatusPayload,
  CAC,
  CreateApplicationFailure,
  CreateApplicationStatus,
  CreateApplicationSuccess,
  EnhancedApplicationStatusPayload,
  ProductNames,
} from '@app-types';
import CheckoutAuthCertificate from '@utils/CheckoutAuthCertificate/index';
import {
  PublicApiApplicationStates,
  PublicApiApplicationStatusErrorResponseBody,
  PublicApiApplicationStatusResponseBody,
  PublicApiCapturedEventPayload,
  PublicApiCreateApplicationResponseBody,
} from '@utils/PublicApiAdapter/types';

function isStatusErrorResponse(
  responseBody: PublicApiApplicationStatusResponseBody | PublicApiApplicationStatusErrorResponseBody
): responseBody is PublicApiApplicationStatusErrorResponseBody {
  return (responseBody as PublicApiApplicationStatusErrorResponseBody).code !== undefined;
}

class PublicApiAdapter implements ApiAdapter {
  private abortController?: AbortController = undefined;

  sendPayload = async (schemaPayload: JSON, submit: string, cac: string): Promise<EnhancedApplicationStatusPayload> => {
    try {
      const mockHeaders = JSON.parse(sessionStorage.getItem('deko-mock-headers'));
      const response = await fetch(submit, {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${cac}`,
          Accept: 'application/hal+json',
          Content: 'application/json',
          'Content-Type': 'application/json',
          ...mockHeaders,
        },
        body: JSON.stringify(schemaPayload),
      });

      const payload: PublicApiCreateApplicationResponseBody = await response.json();
      // eslint-disable-next-line no-underscore-dangle
      const statusUrlString = payload['app:status']._links.self.href;

      if (!statusUrlString) {
        throw new Error('status url string not provided in response payload');
      }

      return {
        status: ApplicationStatus.pending,
        appId: payload?.id,
      };
    } catch (e) {
      return {
        status: ApplicationStatus.failure,
        appId: '',
      };
    }
  };

  captureEvent = async (payload: PublicApiCapturedEventPayload, url: string, cac: CAC): Promise<void> => {
    try {
      await fetch(url, {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${cac}`,
          Accept: 'application/hal+json',
          Content: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(payload),
      });
    } catch (e) {
      throw new Error(e);
    }
  };

  createApplication = async (product: ProductNames, cac: CAC): Promise<Application> => {
    try {
      const authCertificate = CheckoutAuthCertificate(cac);
      const applyUrlString = authCertificate.getApplyUrl(product);

      if (!applyUrlString) {
        throw new Error('apply url is not defined');
      }

      let applyUrl: URL;
      try {
        applyUrl = new URL(applyUrlString);
      } catch (error) {
        throw new Error('apply is not a valid URL');
      }

      const response = await fetch(applyUrl.toJSON(), {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${cac}`,
          Accept: 'application/hal+json',
          'Content-Type': 'application/json',
        },
      });

      const payload: PublicApiCreateApplicationResponseBody = await response.json();
      // eslint-disable-next-line no-underscore-dangle
      const statusUrlString = payload['app:status']._links.self.href;

      if (!statusUrlString) {
        throw new Error('status url string not provided in response payload');
      }

      let { pollInterval } = payload['app:status'];
      if (!pollInterval) {
        pollInterval = 2000;
      }

      return {
        status: CreateApplicationStatus.success,
        pollUrl: statusUrlString,
        pollInterval,
        appId: payload.id,
      } as CreateApplicationSuccess;
    } catch (e) {
      return {
        status: CreateApplicationStatus.failure,
      } as CreateApplicationFailure;
    }
  };

  private generateResponse = (
    status: ApplicationStatus,
    payload: any,
    fallbackStatus: ApplicationStatus.failure | ApplicationStatus.pending | ApplicationStatus.referred,
    reason: ApplicationReasons = null
  ): ApplicationStatusPayload => {
    // eslint-disable-next-line no-underscore-dangle
    if (payload._links?.workflow?.href) {
      return {
        status,
        pollInterval: payload.pollInterval,
        // eslint-disable-next-line no-underscore-dangle
        url: payload._links?.workflow?.href,
        // eslint-disable-next-line no-underscore-dangle
        _links: payload._links,
        reason,
      };
    }
    return {
      status: fallbackStatus,
    };
  };

  getStatus = async (url: string, cac: CAC): Promise<ApplicationStatusPayload> => {
    if (typeof this.abortController !== typeof undefined) {
      this.abortController.abort();
    }
    const mockHeaders = JSON.parse(sessionStorage.getItem('deko-mock-headers'));
    this.abortController = new AbortController();

    const response = await fetch(url, {
      signal: this.abortController.signal,
      method: 'GET',
      headers: {
        Accept: 'application/hal+json',
        Authorization: `Bearer ${cac}`,
        ...mockHeaders,
      },
    });

    const responseBody: PublicApiApplicationStatusResponseBody | PublicApiApplicationStatusErrorResponseBody =
      await response.json();

    if (isStatusErrorResponse(responseBody)) {
      throw new Error(responseBody.message);
    }

    switch (responseBody.state) {
      case PublicApiApplicationStates.success:
        return { status: ApplicationStatus.success, receipt: responseBody.receipt };
      case PublicApiApplicationStates.failed:
        return this.generateResponse(
          ApplicationStatus.failure,
          responseBody,
          ApplicationStatus.failure,
          responseBody?.reason ?? null
        );
      case PublicApiApplicationStates.referred:
        return this.generateResponse(ApplicationStatus.referred, responseBody, ApplicationStatus.referred);
      case PublicApiApplicationStates.denied:
        return this.generateResponse(ApplicationStatus.denied, responseBody, ApplicationStatus.pending);
      case PublicApiApplicationStates.initialised:
      case PublicApiApplicationStates.pending:
      default:
        return this.generateResponse(ApplicationStatus.actionRequired, responseBody, ApplicationStatus.pending);
    }
  };
}

export default PublicApiAdapter;
