import { EventType } from '@app-types';
import type { ApiClientContextInterface } from '@components/ApiClient/types';

export enum SourceComponent {
  DekoWidget = 'Widget',
  Eligibility = 'Eligibility',
  LegalDocs = 'Legal Docs',
  SCA = 'SCA',
  Cookies = 'Cookies',
}

export type TrackEventArguments = {
  pageId: string;
  isStandalone: boolean;
  apiClient: {
    appId: ApiClientContextInterface['appId'];
    captureEvent: ApiClientContextInterface['captureEvent'];
  };
  url: string;
  token: string;
  isInsideCheckout: boolean;
  createdAt?: string;
  stepAction?: string;
  sourceComponent?: SourceComponent;
  type: EventType;
};

export type TrackInteractionEventMessageValues = {
  createdAt: string;
  stepAction: string;
  sourceComponent: SourceComponent;
};

export type TrackComponentRenderEventMessageValues = TrackInteractionEventMessageValues;

export type TrackInteractionEventArgs = Omit<TrackEventArguments, 'type'>;

export type TrackComponentRenderEventArgs = TrackInteractionEventArgs;

export type TrackPageViewEventArguments = Omit<TrackEventArguments, 'type' | 'stepName' | 'createdAt'>;
