import { MutableRefObject, useCallback, useRef } from 'react';

export type FrameSend = (payload: any) => void;
type FrameMessageCallback = (evt: MessageEvent, send?: FrameSend) => void;
type FrameReadyCallback = (send: FrameSend) => void;

const useFrameMessaging = (
  origin: MutableRefObject<string>,
  onFrameReady: FrameReadyCallback,
  onFrameMessage?: FrameMessageCallback
) => {
  const frame = useRef<HTMLIFrameElement>(null);

  const send = (payload: any) => {
    if (frame.current && origin.current) {
      frame.current.contentWindow.postMessage(payload, origin.current);
    }
  };

  const frameMessageHandler = useCallback((evt: MessageEvent) => {
    if (evt.data.message !== undefined && onFrameMessage) onFrameMessage(evt, send);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const setFrame = useCallback((node: HTMLIFrameElement) => {
    if (frame.current) {
      frame.current.removeEventListener('message', frameMessageHandler);
      window.removeEventListener('message', frameMessageHandler);
    }

    if (node) {
      node.addEventListener('message', frameMessageHandler);
      window.addEventListener('message', frameMessageHandler);
      node.addEventListener('load', () => {
        onFrameReady(send);
        node.focus();
      });
    }

    frame.current = node;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return setFrame;
};

export default useFrameMessaging;
