import InitCertificate from '@utils/InitCertificate';
import { InitCertificateLens } from '@utils/InitCertificate/types';

import { dummyPrecheckoutPersistence, PrecheckoutPersistence, PrecheckoutPersistenceKeys } from './types';

const ENABLE_PRECHECKOUT_PERSISTENCE = true;

const clearPrecheckoutState = () => {
  Object.keys(dummyPrecheckoutPersistence).forEach((key: PrecheckoutPersistenceKeys) => {
    sessionStorage.removeItem(key);
  });
};

const clearPrecheckoutStateByKey = (key: PrecheckoutPersistenceKeys) => {
  sessionStorage.removeItem(key);
};

const getPrecheckoutStateByKey = <K extends PrecheckoutPersistenceKeys = PrecheckoutPersistenceKeys>(
  key: K
): PrecheckoutPersistence[K] | null => {
  const value = sessionStorage.getItem(key);
  if (value != null && value !== '') {
    return JSON.parse(value) as PrecheckoutPersistence[K];
  }
  return null;
};

const setPrecheckoutStateByKey = <K extends PrecheckoutPersistenceKeys = PrecheckoutPersistenceKeys>(
  key: K,
  value: PrecheckoutPersistence[K]
) => {
  if (ENABLE_PRECHECKOUT_PERSISTENCE) {
    sessionStorage.setItem(key, JSON.stringify(value));
  }
};

const getPersistedInitToken = (): InitCertificateLens | null => {
  const initToken = getPrecheckoutStateByKey('initToken');
  try {
    if (initToken != null && initToken !== '') {
      const parsedInitToken = InitCertificate(initToken);
      if (
        'expiry' in parsedInitToken &&
        parsedInitToken.expiry instanceof Date &&
        parsedInitToken.expiry > new Date()
      ) {
        return parsedInitToken;
      }
      clearPrecheckoutState();
    }
  } catch (e) {
    clearPrecheckoutState();
  }
  return null;
};

export const PrecheckoutStateManager = {
  clearPrecheckoutState,
  clearPrecheckoutStateByKey,
  getPrecheckoutStateByKey,
  setPrecheckoutStateByKey,
  getPersistedInitToken,
};
