import { ProductNames } from '@app-types';
import calculateInstalment from '@utils/calculateInstalment';
import type { Offers } from '@utils/CheckoutAuthCertificate/types';
import getValidOffers from '@utils/getValidOffers';

import { Instalment } from 'financeInfo/types';
import Cookies from 'js-cookie';
import CookieValues from '@utils/Cookies/types';
import LogosMap from '../logos';
import productFinanceInfo from './productFinanceInfo';

/**
 * Retrieves and displays the optimal FlexPay offer based on the following criteria:
 * 1. If a 0% offer exists, selects the one with the lowest monthly amount payable.
 * 2. If no 0% offers are available, selects the offer with the lowest monthly amount payable overall.
 * 3. In case of ties in monthly amount payable, prioritizes the offer with the lowest total payable cost.
 */
const getPromotedFlexPayTagline = ({
  offer,
  tagFormat,
}: {
  offer: Instalment;
  tagFormat: (value: number) => number;
}): string => {
  const customerIsEligible: boolean =
    Cookies.get('EligibilityCheckStatus') === CookieValues.PREQUALIFICATION_ACCEPT ||
    Cookies.get('EligibilityCheckStatus') === 'true';
  if (!offer) {
    return `Finance available.${!customerIsEligible ? ' Credit subject to status.' : ''}`;
  }
  if (offer.apr === 0) {
    return `From £${tagFormat(offer.monthlyInstalment).toFixed(2)} a month with 0% interest.${
      !customerIsEligible ? ' Credit subject to status.' : ''
    }`;
  }
  return `Instalment plans ${
    !customerIsEligible ? 'available.' : `from £${tagFormat(offer.monthlyInstalment).toFixed(2)}`
  }${!customerIsEligible ? ' Credit subject to status' : ' per month'}.`;
};

const getOptimalFlexPayOffer = (offers: Instalment[]) => {
  const zeroPercentOffers = offers.filter((offer) => offer.apr === 0);

  if (zeroPercentOffers.length > 0) {
    return zeroPercentOffers.reduce((bestOffer, currentOffer) => {
      if (
        currentOffer.monthlyInstalment < bestOffer.monthlyInstalment ||
        (currentOffer.monthlyInstalment === bestOffer.monthlyInstalment && currentOffer.total < bestOffer.total)
      ) {
        return currentOffer;
      }
      return bestOffer;
    }, zeroPercentOffers[0]);
  }

  return offers.reduce((bestOffer, currentOffer) => {
    if (
      currentOffer.monthlyInstalment < bestOffer.monthlyInstalment ||
      (currentOffer.monthlyInstalment === bestOffer.monthlyInstalment && currentOffer.total < bestOffer.total)
    ) {
      return currentOffer;
    }
    return bestOffer;
  }, offers[0]);
};

const specificProductFinanceInfo = (
  offers: Offers[],
  amount: number,
  productName: ProductNames = ProductNames.BLACKHORSE_FLEXPAY
) => {
  const basketAmount = amount / 100;
  const validOffers = getValidOffers(offers, amount);

  const instalments = validOffers.map((offer) => calculateInstalment(basketAmount, offer));
  const interestFreeInstalments = validOffers
    .filter((offer) => offer.apr === 0)
    .map((offer) => calculateInstalment(basketAmount, offer));
  const { productLogo, eligibilityLogo } = LogosMap[productName];
  const tagFormat = (value: number) => value;

  let promotedTagline = '';
  if (ProductNames.BLACKHORSE_FLEXPAY === productName) {
    const optimalOffer: Instalment = getOptimalFlexPayOffer(instalments);
    promotedTagline = getPromotedFlexPayTagline({ offer: optimalOffer, tagFormat });
  }

  return productFinanceInfo(
    instalments,
    interestFreeInstalments,
    amount,
    productLogo,
    tagFormat,
    promotedTagline,
    eligibilityLogo,
    "You're Eligible",
    true
  );
};

export default specificProductFinanceInfo;
