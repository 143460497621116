export const RETRY_EXPONENT = 2;
export const MAX_CONSECUTIVE_FAILURES = 5;
export const ENABLE_DEBUG_LOGGING = false;

export type ExponentialBackoffOptions = {
  functionName?: string;
  timeoutFn?: (fn: () => void, time: number) => number;
  maxAttempts?: number;
};

export function exponentialBackoff<T>(
  fn: () => Promise<T>,
  timeout: number,
  { functionName = '', timeoutFn = setTimeout, maxAttempts = MAX_CONSECUTIVE_FAILURES }: ExponentialBackoffOptions,
  shouldErrorBeRetried: (error: any) => boolean = () => true
): Promise<T> {
  let failures = 0;

  async function attempt(): Promise<T> {
    try {
      if (ENABLE_DEBUG_LOGGING) {
        console.log(`Executing ${functionName}`);
      }
      const response = await fn();
      if (ENABLE_DEBUG_LOGGING) {
        console.log(`Successfully executed ${functionName}`, response);
      }
      return response;
    } catch (e) {
      const error = e;
      if (ENABLE_DEBUG_LOGGING) {
        console.error(`Failed to execute ${functionName}. Attempt: ${failures}`, error);
        console.log(`Failed to execute ${functionName}. Remaining attempts: ${maxAttempts - failures}`);
      }
      failures += 1;

      if (failures >= maxAttempts || !shouldErrorBeRetried(error)) {
        if (ENABLE_DEBUG_LOGGING) {
          console.log(`Exceeded maximum number of attempts for ${functionName}.`);
        }
        return Promise.reject(e);
      }

      const backoffTime = RETRY_EXPONENT ** failures * timeout;
      return new Promise((resolve) => {
        timeoutFn(() => resolve(attempt()), backoffTime);
      });
    }
  }

  return attempt();
}
