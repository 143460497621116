import Cookies from 'js-cookie';

import { ApplicationEventType, LenderName, SchemaObject } from '@app-types';
import AppPropManager from '@utils/AppPropManager';
import MessageCommands from '@utils/MessageListener/types';
import sendMessage from '@utils/sendMessage';

import { CookieOptions, CookieType, EligibilityCheckStatusType, EligibilityStatus } from './types';

const expires = new Date(new Date().getTime() + 4 * 60 * 60 * 1000); // Expiration time is set to 4 hours
const setOrRemoveCookie = (
  shouldSetCookie: boolean,
  cookieName: string,
  value?: any,
  cookieOptions?: CookieOptions
) => {
  if (shouldSetCookie && value !== undefined) {
    Cookies.set(cookieName, value, cookieOptions);
  } else {
    Cookies.remove(cookieName);
  }
};

const updateCookieValues = (
  lender: LenderName,
  eligibilityStatus: EligibilityStatus,
  appId?: string,
  limit?: number,
  apr?: number
) => {
  const isCounterOffer = eligibilityStatus === EligibilityCheckStatusType.COUNTEROFFER;
  const isDeclined = eligibilityStatus === EligibilityCheckStatusType.DECLINED;
  let isActive: boolean | undefined;
  const cookieOptions = { expires };

  if (eligibilityStatus === EligibilityCheckStatusType.ACCEPTED) isActive = true;
  if (isDeclined) isActive = false;
  if (isCounterOffer) isActive = undefined;

  setOrRemoveCookie(!isCounterOffer, CookieType.EligibilityCheckStatus, isActive, cookieOptions);
  setOrRemoveCookie(!isCounterOffer && !isDeclined, CookieType.EligibilityCheckLimit, limit, cookieOptions);
  setOrRemoveCookie(!isCounterOffer && !isDeclined, CookieType.EligibilityCheckApr, apr, cookieOptions);
  setOrRemoveCookie(!isDeclined, CookieType.EligibilityCheckLender, lender, cookieOptions);
  if (eligibilityStatus === EligibilityCheckStatusType.ACCEPTED) {
    setOrRemoveCookie(true, CookieType.EligibilityApplication, appId, cookieOptions);
  } else {
    setOrRemoveCookie(false, CookieType.EligibilityApplication);
  }
};

const updateBeaconStatus = (
  schema: SchemaObject,
  onApplicationEvent: (eventType: ApplicationEventType) => void,
  appId: string
) => {
  // TODO: UPDATE WHEN BE IS READY
  const page = schema._page;
  const isFlexPaySuccess = page === 'FlexPayEligibilityApproval' || page === 'FlexPaySuccess';
  const isCounterOffer = page === 'NewPayCounterOffer';
  const isNewPaySuccess = page === 'NewPayEligibilityApprovalPage';
  const isDoNotLend = page === 'FlexPayEligibilityDeclined';
  const isRejectedCounterOffer = page === 'NewPayEligibilityDeclined';

  if (isFlexPaySuccess || isNewPaySuccess) {
    updateCookieValues(
      schema.lender,
      EligibilityCheckStatusType.ACCEPTED,
      appId,
      schema.meta.creditInfo.assumedCreditLimit,
      schema.meta.creditInfo.apr
    );

    if (schema.lender === LenderName.BLACKHORSE) {
      AppPropManager.handleFinanceInfoUpdate();
    }

    if (schema.lender === LenderName.NEWPAY) {
      onApplicationEvent(ApplicationEventType.PREQUALIFICATION_ACCEPT);
      AppPropManager.handlePartnerChange(schema.lender);
    }
  }

  if (isCounterOffer) {
    updateCookieValues(schema.lender, EligibilityCheckStatusType.COUNTEROFFER);
    AppPropManager.handlePartnerChange(schema.lender);
  }

  if (isDoNotLend || isRejectedCounterOffer) {
    updateCookieValues(undefined, EligibilityCheckStatusType.DECLINED);
    sendMessage({ message: MessageCommands.PREQUALIFICATION_DECLINE });
  }
};

export default updateBeaconStatus;
